import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Logo from "../../assets/images/omw-logo.png"

import { Container, Form, Spinner } from "react-bootstrap";
import { errorMsgs, regexStrConst } from "../../constants/stringConstants";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { getAllAccounts } from "../../redux/toolkit/getAllUserAccount/operation";
import { AppDispatch, RootState } from "../../redux/store";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import "./index.css";
import { useDispatch } from "react-redux";
import {
  PayexInvoice,
  SendInvoiceEmail,
  SendGeneratedInvoiceEmail
} from "../../redux/toolkit/verifyPayment/operation";
import html2pdf from "html2pdf.js/dist/html2pdf.js";
import { OMWLogoBlack } from "../../constants/Images";
import ModalHeader from "../../components/shared/Modal/ModalHeader";

type itemList = {
  itemDescription: string;
  amount: number;
  quantity: number;
  unitPrice: number;
};

const InvoiceModal = (props: any) => {
  console.log("Previewprops: ", props);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [accountTypeError, setAccountTypeError] = useState("");

  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceDateError, setInvoiceDateError] = useState("");

  // Reason states

  const [invoiceReasonDes, setInvoiceReasonDes] = useState("");
  const [invoiceReasonDesError, setInvoiceReasonDesError] = useState("");

  // Items List
  const [listItemDescription, setListItemDescription] = useState("");
  const [listItemDesErrorMsg, setListItemDesErrorMsg] = useState("");

  const [listItemRate, setListItemRate] = useState("");
  const [listItemRateErrorMsg, setListItemRateErrorMsg] = useState("");

  const [listItemQauntity, setListItemQauntity] = useState("");
  const [listItemQauntityError, setListItemQauntityError] = useState("");

  const [itemError, setItemError] = useState("");

  const [invoiceAddress, setInvoiceAddress] = useState("");
  const [invoiceAddressError, setInvoiceAddressError] = useState("");

  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddressError, setStreetAddressError] = useState("");

  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");

  const [coAddress, setCoAddress] = useState("");
  const [coAddressError, setCoAddressError] = useState("");

  const [countryCode, setCountryCode] = useState("");
  const [countryCodeError, setCountryCodeError] = useState("");

  const [totalAmount, setTotalAmount] = useState(0);

  const [itemsList, setItemsList] = useState<itemList[]>([]);

  const [isChecked, setIsChecked] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [isSentloading, setIsSentloading] = useState(false);

  const accountId: any = localStorage.getItem("accountId");
  const [accUUID, setAccUUID] = useState(accountId);
  const [accountDetails, setAccountDetails] = useState<any>([]);
  const [logoImagebase64, setlogoImagebase64] = useState('');
  const accountIdSelecto: any = useSelector(
    (state: RootState) => state.getAllAccounts.data
  );

  const accData: any = localStorage.getItem("AccountData");
  const ParsedData: any = JSON.parse(accData);
  const accId: any = localStorage.getItem("activeAccId");
  const companyLogo: any = localStorage.getItem("companyLogo")

  const userId: any = localStorage.getItem("userId");
  const senderCompanyRegNumber: any = localStorage.getItem('companyRegNumber')
  const senderCity: any = localStorage.getItem("city");
  const senderPostalCode: any = localStorage.getItem("postalCode");
  const senderCountry: any = localStorage.getItem("country");
  const senderCompany: any = localStorage.getItem("companyName");
  const senderCompanyAddress: any = localStorage.getItem("companyAddress");
  const lang: any = localStorage.getItem("lng");
  let bnkCode="";

  if (props?.info?.accountInfo?.IBAN?.slice(0, 2) === "DK") {
    bnkCode=props?.info?.accountInfo?.IBAN.slice(4, 8);
  }

  const dispatch = useDispatch<AppDispatch>();
  const invoiceModalRef = useRef(null);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    dispatch(getAllAccounts(userId));
  }, [dispatch]);

  useEffect(() => {
    if (accountIdSelecto) {
      console.log("selected account data is", accountIdSelecto);
      setAccountDetails(accountIdSelecto?.data?.data);
    }
  }, [accountIdSelecto, accountDetails]);

  useEffect(() => {
    if (itemsList.length > 0) {
      setItemError("");
    }
  }, [itemsList]);

  console.log("invoiceDate: ", invoiceDate);

  const validate = () => {
    console.log("account type", accountType);
    let check = false;
    if (accountType === "") {
      setAccountTypeError(errorMsgs.emptyAccountType);
      check = true;
    }
    if (invoiceDate === "") {
      setInvoiceDateError(errorMsgs.emptyDate);
      check = true;
    }
    if (invoiceReasonDes === "") {
      setInvoiceReasonDesError(errorMsgs.emptyIRDes);
      check = true;
    }
    if (invoiceAddress === "") {
      setInvoiceAddressError(errorMsgs.emptyName);
      return true;
    }
    if (streetAddress === "") {
      setStreetAddressError(errorMsgs.emptyStreetAddress);
      return true;
    }
    if (zipCode === "") {
      setZipCodeError(errorMsgs.emptyZipCode);
      return true;
    }
    if (city === "") {
      setCityError(errorMsgs.emptyCity);
      return true;
    }
    if (coAddress === "") {
      setCoAddressError(errorMsgs.emptyCoAddress);
      return true;
    }
    if (countryCode === "") {
      setCountryCodeError(errorMsgs.emptyCountryCode);
      return true;
    }
    if (itemsList.length === 0) {
      setItemError(errorMsgs.emptyItemList);
      check = true;
    }
    return check;
  };

  const sendPayexButtonVerification = () => {
    if(props?.info?.accountInfo?.currency === "SEK") {
      if(props?.info?.userDetailPayex?.ledgerNumber &&
        props?.info?.userDetailPayex?.payex_auth_token) return false
      else return true;
    } else return false;
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validate()) {
      // if have any error
    } else {
      // api dispatch fuction goes here.
      setLoading(true);
      const payload = {
        accountID: accUUID,
        // hardcore value for testing purpose of the swedish acconts
        // accountID: "e2ad7ee7-1e93-45fd-be48-abf3f9fec5b2",
        includeTax: isChecked,
        paymentType: accountType,
        invoiceDate: invoiceDate,
        invoiceReason: {
          invoiceReasonDescription: invoiceReasonDes,
        },
        invoiceSpecification: {
          invoiceLines: itemsList,
        },
      };
      console.log("payex api call payload is", payload);
      dispatch(PayexInvoice(payload)).then((data: any) => {
        setLoading(false);
        setInvoiceReasonDes("");
        setListItemDescription("");
        setTotalAmount(0);
        setItemsList([]);
        setLoading(false);
        setIsChecked(false);
      });
    }
  };

  const onCreateList = () => {
    if (!listItemDescription || !listItemQauntity || !listItemRate) {
      setListItemDesErrorMsg(errorMsgs.emptyItemDes);
      setListItemQauntityError(errorMsgs.emptyItemAmount);
      setListItemRateErrorMsg(errorMsgs.emptyItemAmount);
    } else {
      setListItemDesErrorMsg("");
      setListItemQauntityError("");
      setListItemRateErrorMsg("");
      console.log("quantity is", listItemQauntity);
      console.log("type of quantity is", typeof listItemQauntity);
      console.log("rate is", listItemRate);
      console.log("type of rate is", typeof listItemRate);
      const res = {
        itemDescription: listItemDescription,
        quantity: parseInt(listItemQauntity),
        unitPrice: parseInt(listItemRate),
        amount: parseInt(listItemQauntity) * parseInt(listItemRate),
      };
      setItemsList([...itemsList, res]);
      setTotalAmount(
        (prev) => prev + parseInt(listItemQauntity) * parseInt(listItemRate)
      );
      setListItemRate("");
      setListItemDescription("");
      setListItemQauntity("");
    }
  };

  const removeItemfromList = (e: any) => {
    console.log("itemIndexxxxx", e);
    const list = [...itemsList];
    const newList = list.splice(e, 1);
    setItemsList(list);
    console.log("newList: ", newList);
  };
  console.log("ItemsList=====> ", itemsList);

  const createInvoicePDF = () => {
    setIsloading(true);
    const element = document.getElementById("invoiceCapture"); // Replace 'html-content' with the id of your HTML element
    const invoiceFileName = `${props?.info?.invoiceNumber}_${new Date()
      .toISOString()
      .slice(0, 10)}.pdf`;
    const opt = {
      filename: invoiceFileName,
      margin: [0, 0, 0, 0],
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(opt).from(element).save();
    setIsloading(false);
  };
  function formatDate(dateString: any) {
    console.log("INPUT DATE", dateString)
    const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];

    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const monthIndex = dateObj.getMonth();
    const year = dateObj.getFullYear();

    const formattedDate = `${day}-${months[monthIndex]}-${year}`;
    console.log("TEST DATE :::: ",formattedDate)
    return formattedDate;
  }

  const sendInvoicePDF = async () => {
    try {
      setIsSentloading(true);
      const element = document.getElementById("invoiceCapture"); // Replace 'html-content' with the id of your HTML element
      const invoiceFileName = `${props?.info?.invoiceNumber}_${new Date()
        .toISOString()
        .slice(0, 10)}.pdf`;
      const opt = {
        filename: invoiceFileName,
        margin: [0, 0, 0, 0],
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      const pdf = await html2pdf().set(opt).from(element).toPdf().output('blob');

      const formData = new FormData();
      formData.append('pdfFile', pdf, 'filename.pdf');
      formData.append('lang', lang);
      formData.append('invoiceNumber', props.info.invoiceNumber);
      formData.append('to', props.info.email);
      formData.append('issue_date', formatDate(issueDate));
      formData.append('due_date', formatDate(props?.info?.dateOfIssue));
      formData.append('total_amount_due', props?.info?.totalAmount);
      formData.append('companyName', senderCompany);
      formData.append('currency', props?.currency);
      formData.append('accountType', props?.info?.accountType);
      
                
  
      // const extraInfo = {
      //   senderCity,
      //   senderCountry,
      //   senderPostalCode,
      //   senderCompany,
      //   senderCompanyRegNumber,
      //   lang,
      //   props
      // };
      dispatch(SendGeneratedInvoiceEmail(formData)).then((data: any) => {
        setIsSentloading(false);
      });
    } catch(error) {
      console.log('SOME ERROR OCCURED ', error)
    }
  };

  const issueDate = new Date().toISOString();
  console.log('condition', props?.info?.accountInfo?.currency)




  function imageToBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';

        xhr.onload = () => {
            if (xhr.status === 200) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result as string);
                };
                reader.onerror = reject;
                reader.readAsDataURL(xhr.response);
            } else {
                reject(new Error(`HTTP error! status: ${xhr.status}`));
            }
        };

        xhr.onerror = () => {
            reject(new Error('An error occurred during the XMLHttpRequest'));
        };

        xhr.send();
    });
}

 //this is to test the code if we are getting base 64 from url
  imageToBase64(props.info.companyLogo)
      .then((base64String) => {
        // console.log("Image to be checked",base64String)
        setlogoImagebase64(base64String)
      })
      .catch(error => console.error("THIS IS THE ERROR ON IMAGE",error));



  return (
    <div>
      <Modal
        style={{ marginTop: 50, padding: "0px 0px 20px 0px"}}
        show={props.showModal}
        onHide={props.closeModal}
        size="lg"
        centered
        className="modal-containter"
      >
       
        {/* <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
            <div>
              <Modal.Title>Close</Modal.Title>
            </div>
            <IconButton style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }} onClick={props.closeModal}>
              <CloseIcon style={{ fontSize: "1.2rem" }} />
            </IconButton>
          </Modal.Header> */}
          <ModalHeader title="" onClose={() => props.closeModal()} modalBodyRef={invoiceModalRef}/>
{/* this id invoiceCapture is responsible to generated and send pdf if it is removed invoice pdf will not be generated or send */}
    <Container id="invoiceCapture" ref={invoiceModalRef} className="modal-body">
      <Row className="d-flex justify-content-between px-3 p-md-3" >
        <Col xs={6} sm={6}>
          <p className="invoiceHeading">Invoice</p>
        </Col>
        <Col xs={4} sm={6} md={6} lg={6} xl={4}>
        <div style={{ float: "right" }}>
        <img
         style={{ width: "200px", height:"68px" }}
        //  crossOrigin="anonymous"
         src={
         props.info.companyLogo && props.info.companyLogo.includes("CompanyLogos")
        ? logoImagebase64
        : OMWLogoBlack
    }
  />
</div>
        </Col>
      </Row>
        <Row className="p-3">
          <Col sm={12} md={12} lg={4} xl={4} className="invoicedes">
                <table style={{borderCollapse:'separate', borderSpacing:'0 8px'}}>
                  <tbody>
                    <tr className="mb-5">
                      <td className="invoiceLabel" style={{marginBottom:"10px"}}>
                        Invoice No.
                      </td>
                      <td className="invoiceVal">
                       # {props?.info?.invoiceNumber}
                      </td>
                    </tr>
                    <tr>
                      <td className="invoiceLabel">
                      {t("invoice.preview.dateOfIssue")}.
                      </td>
                      <td className="invoiceVal">
                      {formatDate(issueDate)}
                      </td>
                    </tr>
                    <tr>
                      <td className="invoiceLabel">
                      {t("invoice.preview.dueDate")}.
                      </td>
                      <td className="invoiceVal">
                      {formatDate(props?.info?.dateOfIssue)}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* <div className="d-flex align-items-center">
                  <p className="invoiceLabel">Invoice No.</p>
                  <p className="invoiceVal mx-2">{props?.info?.invoiceNumber}</p>   
                </div>
                <div className="d-flex align-items-center">
                  <p className="invoiceLabel">{t("invoice.preview.dateOfIssue")}.</p>
                  <p className="invoiceVal mx-2">{formatDate(issueDate)}</p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="invoiceLabel">{t("invoice.preview.dueDate")}.</p>
                  <p className="invoiceVal mx-2">{formatDate(props?.info?.dateOfIssue)}</p>
                </div> */}
                {/* <Col sm={12} md={12} lg={12} xl={12} className="d-flex justify-content-between align-items-center" style={{background:"red"}}>
                  <p className="invoiceLabel">Invoice No.</p>
                  <p className="invoiceVal">{props?.info?.invoiceNumber}12345</p>
                </Col>
                <Col sm={12} md={12} lg={12} xl={12} className="d-flex justify-content-between align-items-center" style={{background:"red"}}>
                  <p className="invoiceLabel">{t("invoice.preview.dateOfIssue")}.</p>
                  <p className="invoiceVal">{formatDate(issueDate)}</p>
                </Col>
                <Col sm={12} md={12} lg={12} xl={12} className="d-flex justify-content-between align-items-center" style={{background:"red"}}>
                  <p className="invoiceLabel">{t("invoice.preview.dueDate")}.</p>
                  <p className="invoiceVal">{formatDate(props?.info?.dateOfIssue)}</p>
                </Col> */}
          </Col>
          <Col>
          
          </Col>
        </Row>
        <Row className="mt-0 mt-lg-3 p-3">
          <Col xs={6}  sm={12} md={6} lg={6} xl={6}>
            <Row>
              <Col>
                <p className="billed">{t("invoice.preview.billedTo")}:</p>
                <p className="billedName my-1">{props?.info?.billedTo}</p>
                { props?.info?.selectedType === "Company" && props?.info?.compRegNo && (
                <div className="d-flex align-items-center">
                  <p className="billedDetails my-1 text-nowrap">{t("invoice.preview.regNo")}:</p>
                  <p className="billedDetails m-1"> {props?.info?.compRegNo}</p>
                </div>
                )}
                <div className="d-flex ">
                  <p className="billedDetails">{t("invoice.preview.address")}: {props?.info?.streetAddress}, {props?.info?.city}, {props?.info?.zipCode} {props?.info?.countryName}</p>
                  {/* <p className="billedDetails mx-2">{props?.info?.streetAddress}, {props?.info?.city}, {props?.info?.zipCode} {props?.info?.countryCode}</p> */}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={6} sm={12} md={6} lg={6} xl={6}>
          <Row>
              <Col>
                <p className="billed">{t("invoice.preview.billedFrom")}:</p>
                <p className="billedName my-1">{senderCompany}</p>
                <div className="d-flex align-items-center">
                  <p className="billedDetails my-1 text-nowrap">{t("invoice.preview.regNo")}:</p>
                  <p className="billedDetails m-1"> {senderCompanyRegNumber}</p>
                </div>
                <div className="d-flex">
                  <p className="billedDetails m-0">{t("invoice.preview.address")}: {senderCompanyAddress || "-"}</p>
                  {/* <p className="billedDetails m-0">{t("invoice.preview.address")}: {props?.info?.streetAddress}, {senderCity|| "-"}, {senderPostalCode|| "-"} {senderCountry|| "-"}</p> */}
                  {/* <p className="billedDetails m-0 px-2">{props?.info?.streetAddress}, {senderCity|| "-"}, {senderPostalCode|| "-"} {senderCountry|| "-"}</p> */}
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={12} lg={12} xl={12}>
            <Table className="invoiceListModal mb-0 mt-3">
              <thead>
                <tr>
                  <td className="invoice-th">Sr.</td>
                  <td className="invoice-th">{t("invoice.preview.description")}</td>
                  <td className="invoice-th text-end">{t("invoice.preview.qty")}</td>
                  <td className="invoice-th text-end">{t("invoice.preview.price")}</td>
                  <td className="invoice-th text-end">{t("invoice.preview.amount")}</td>
                </tr>
              </thead>
              <tbody>
                {props?.items?.map((ele: any, index: any) => (
                  <tr key={index}>
                    <td className="invoice-th invoice-sr" style={{width:'20px'}}>{index + 1}</td>
                    <td className="invoice-dec" style={{width:'200px'}}>{ele.description}</td>
                    <td className="invoice-th invoice-qpa text-end" style={{width:'110px'}}>{ele.quantity}</td>
                    <td className="invoice-th invoice-qpa text-end" style={{width:'120px'}}>{props.currency} {ele.price}</td>
                    <td className="invoice-th invoice-qpa text-end" style={{width:'120px'}}>
                      {props.currency} {ele.price * ele.quantity}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <hr />

            

            {/* <Table className="invoiceTotalTbl">
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>{t("invoice.preview.subTotal")}</td>
                  <td></td>
                  <td className="text-end" style={{ width: '100px' }}>{props.currency} {props?.info?.subTotal}</td>
                </tr>
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>{t("invoice.form.itemsListTotal.tax")}</td>
                  <td></td>
                  <td className="text-end" style={{ width: '100px' }}>{props.currency} {props?.info?.taxableAmount}</td>
                </tr>
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>{t("invoice.preview.total")}</td>
                  <td></td>
                  <td className="text-end" style={{ width: '100px' }}>{props.currency} {props?.info?.totalAmount}</td>
                </tr>
              </tbody>
            </Table> */}
          </Col>
          <Col sm={12} md={12} lg={12} xl={12}>
            <Row className="d-flex justify-content-end mt-5">
            <Col sm={12} md={7} lg={7} xl={7} className="align-self-end">
                <Row className="d-flex justify-content-between">
                  <Col lg={12}>
                    <Row>
                      <Col><p className="invoiceSubTotalLabel">{t("invoice.preview.subTotal")}</p></Col>
                      {/* <Col className="text-end"><p className="invoiceSubTotalVal">{props.currency} {props?.info?.subTotal}</p></Col> */}
                      <Col className="text-end"><p className="invoiceSubTotalVal">{props.info?.accountType === "credit" ? '-' : ''} {props.currency} {props?.info?.subTotal}</p></Col>
                    </Row>
                  </Col>
                  <Col lg={12}>
                     <Row>
                      <Col><p className="invoiceSubTotalLabel m-0">{t("invoice.form.itemsListTotal.tax")}</p></Col>
                      <Col className="text-end"><p className="invoiceSubTotalVal m-0">{props.currency} {props?.info?.taxableAmount}</p></Col>
                    </Row>
                  </Col>
                  <Col>
                    <hr/>
                  </Col>
                  <Col lg={12} className="amountDueBox">
                     <Row >
                      <Col><p className="invoiceSubTotalLabel m-0">{t("invoice.preview.total")}</p></Col>
                      <Col className="text-end"><p className="amountDueVal">{props.info?.accountType === "credit" ? '-' : ''} {props.currency} {props?.info?.totalAmount}</p></Col>
                    </Row>
                  </Col>
                </Row>
            </Col>
           
            </Row>
          </Col>
          
          <Col sm={12} md={12} lg={12} xl={12} className="mt-3 mt-sm-5">
            <Row>
              <p className="accountDetailsHeading">Bank Account Details</p>

              <Col md={4} style={{ width: "100%" }} className="bankAccountDetailsTable">
                <table> 
                <tbody>
                    <tr>
                        <td className="invoiceLabel py-1">{t("invoice.preview.iban")}:</td>
                        <td className="invoiceVal">{props?.info?.accountInfo?.IBAN|| "-"}</td>
                    </tr>
                    <tr>
                        <td className="invoiceLabel py-1">{t("invoice.preview.bank")}:</td>
                        <td className="invoiceVal">{props?.info?.accountInfo?.bank || "-"}</td>
                    </tr>
                    <tr>
                        <td className="invoiceLabel py-1">{t("invoice.preview.bankAddress")}:</td>
                        <td className="invoiceVal">{props?.info?.accountInfo?.bankAddress|| "-"}</td>
                    </tr>
                    <tr>
                        <td className="invoiceLabel py-1">{t("invoice.preview.accountName")}:</td>
                        <td className="invoiceVal">{props?.info?.accountInfo?.accountName|| "-"}</td>
                    </tr>
                    { (props?.info?.accountInfo?.IBAN.slice(0, 2) === "DK" && props?.info?.accountInfo?.currency === 'DKK') && <>
                    <tr>
                        <td className="invoiceLabel py-1">{t("invoice.preview.accountNumber")}:</td>
                        <td className="invoiceVal">{props?.info?.accountInfo?.accountNumber || props?.info?.accountInfo?.IBAN.slice(-10)}</td>
                    </tr>
                    <tr>
                        <td className="invoiceLabel py-1">{t("invoice.preview.bankCode")}: </td>
                        <td className="invoiceVal">{props?.info?.accountInfo?.IBAN?.slice(4, 8)}</td>
                    </tr>
                    </>

                    }
                   
                    

                  {props?.info?.accountInfo?.swiftCode !== '' && (
                    <tr>
                         <td className="invoiceLabel py-1">{t("invoice.preview.swiftCode")}:</td>
                         <td className="invoiceVal">{props?.info?.accountInfo?.swiftCode}</td>
                    </tr>
                  )}
                  {props?.info?.accountInfo?.sortCode  !== '' && (
                    <tr>
                        <td className="invoiceLabel py-1">{t("invoice.preview.sortCode")}:</td>
                        <td className="invoiceVal">{props?.info?.accountInfo?.sortCode}</td>
                    </tr>
                  )}
                    
                </tbody>
            </table>
                {/* =============================================================================== */}
              </Col>
            </Row>
          </Col>
        </Row>
        {props.info.companyLogo && props.info.companyLogo.includes("CompanyLogos") && (
            <Row>
              <div className="footerDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>
              {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}> */}
                <span style={{ fontSize: "11px" }}>

                {t("invoice.preview.footertext")}
                </span>
                <div style={{ width: "80px" }}>


                <img
                className="footerImage"
                  style={{width:"100%"}}
                  src={OMWLogoBlack}
                  alt="OneMoneyWay Logo"
                />

                </div>
              </div>
            </Row>
          )}
    {/* ////////////////////// */}

        
    </Container>
    <Container style={{paddingBottom: "16px"}}>
    <Row className="m-0 m-sm-3 ">
        {props.continueBtn && !props?.isOpenEditPreview  && props?.info?.accountInfo?.currency !== "SEK"? (
          <Col sm={12} md={12} lg={6} xl={6} className="mx-auto">
            <Button variant="primary" type="submit"
              disabled={sendPayexButtonVerification() || props.generateInvoiceLoading ? true : false}
              onClick={props.onGenerateInvoice}
              className="d-block w-100">
                
                {props.generateInvoiceLoading ?

                (<Spinner animation={"border"} size="sm"/>)
                :
                t("invoice.preview.continue")
                }
             </Button>
          </Col>
                ) : null}      
            

            {props.continueBtn && !props?.isOpenEditPreview && props?.info?.accountInfo?.currency === "SEK"? (
          <Col sm={12} md={12} lg={6} xl={6}>
            <Button variant="primary" type="submit"
              disabled={sendPayexButtonVerification() || props.generateInvoiceLoading ? true : false}
              onClick={props.onGenerateInvoice}
              className="d-block w-100">
                {props.generateInvoiceLoading ?
                (<Spinner animation={"border"} size="sm"/>):
                t("invoice.preview.sendPayex")
                }
             </Button>
          </Col>
                ) : null}
            {!props.continueBtn ? (
          <Col xs={6}  sm={12} md={12} lg={6} xl={6} className="genPdf">
              <Button variant="primary" type="button"
                style={{fontWeight: 600}}
                disabled={props.generateInvoiceLoading ? true : false}
                onClick={createInvoicePDF}
                className="d-block w-100 mt-2">{isloading ?
                  (<Spinner animation={"border"} size="sm"/>)
                  : t("invoice.preview.generatePDF")}
              </Button>
          </Col>
            ) : null}
              {!props.continueBtn ? (
          <Col xs={6} sm={12} md={12} lg={6} xl={6} className="sendPdf">
                <Button variant="outline-primary" type="button"
                style={{fontWeight: 600}}
                  disabled={props.generateInvoiceLoading ? true : false}
                  onClick={sendInvoicePDF}
                  className="d-block w-100 mt-2">{isSentloading ?
                    (<Spinner animation={"border"} size="sm"/>)
                    : t("invoice.preview.sendPDF")}
                </Button>
          </Col>
              ): null}

          </Row>


          

          </Container>
          
      </Modal>
    </div>
  );
};

export default InvoiceModal;
