import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import ModalHeader from "../Modal/ModalHeader";
import { useTranslation } from "react-i18next";

const TransactionDetail = (props: any) => {
  const [transactionModal, setTransactionModal] = useState(false);
  const [transactionData, setTransactionData] = useState<any>({});
  const handleCloseTransaction = () => setTransactionModal(!transactionModal);

  const companyName: any = localStorage.getItem("companyName");
  const { t } = useTranslation();
  const { isShow, data } = props;

  useEffect(() => {
    setTransactionModal(isShow);
    setTransactionData(data);
    console.log('.... data ', data);
  }, [isShow, data])

  const dateFormatter = (datetoConvert = "") => {
    if(datetoConvert) {
      const formattedDate = new Date(datetoConvert).toISOString().split("T")[0];
      const [year, month, day] = formattedDate.split('-');
      return `${day}-${month}-${year}`;
    } 
    return '';
  }

  return (
    <>
      <Modal
        // style={{ marginTop: 100 }}
        show={transactionModal}
        onHide={handleCloseTransaction}
        backdrop="static"
        keyboard={false}
        className="transactionDetailsModal"
      >
        {/* <Modal.Header closeButton className="mt-4"> */}
        {/* <Modal.Title>
            {t("recentOperation.viewDetailModal.title")}
          </Modal.Title> */}
        {/* </Modal.Header> */}
        <ModalHeader onClose={handleCloseTransaction} />
        <Modal.Body className="pt-3 pb-5 transactionDetailsModal-body">
          <div className="text-center">
            <p className="transactionDetailHeading">
              {t("recentOperation.viewDetailModal.title")}
            </p>
            <p className="transactionDetail-subHeading m-0">
              {t("recentOperation.viewDetailModal.amount")}
            </p>
            <p className="paymentAmountAmount">
              {transactionData?.payment_amount} {transactionData?.payment_currency}
            </p>
          </div>

          <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              {/* {transactionData?.posting_key == "cr" ? (
                <p style={{ fontWeight: 600 }} className="text-start">
                  {t("recentOperation.viewDetailModal.companyName")}:
                </p>
              ) : (
                <p style={{ fontWeight: 600 }} className="text-start">
                  {t("recentOperation.viewDetailModal.recipientName")}:
                </p>
              )} */}
               <p style={{ fontWeight: 600 }} className="text-start">
                  {t("recentOperation.viewDetailModal.beneficiaryName")}
                </p>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p className="text-end">
                {/* {transactionData?.posting_key == "cr"
                  ? companyName
                  : transactionData?.offsetting_transaction_entity
                  ? transactionData?.offsetting_transaction_entity
                  : "Self"} */}
                  {transactionData?.beneficiary_name}
              </p>
            </Col>
          </Row>
          {/* {transactionData?.beneficiary_iban && (
            <Row>
              <Col xs={6} sm={6} md={6} lg={6}>
                <p style={{ fontWeight: 600 }} className="text-start">
                  IBAN:
                </p>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6}>
                <p className="text-end">{transactionData?.beneficiary_iban}</p>
              </Col>
            </Row>
          )} */}

          <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p style={{ fontWeight: 600 }} className="text-start">
                {t("recentOperation.viewDetailModal.transactionDate")}:
              </p>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p className="text-end">
                {dateFormatter(transactionData?.createdAt?.slice(0, 10))}
              </p>
            </Col>
          </Row>

          {/* {transactionData?.posting_key == "cr" &&
            transactionData?.payer_account && (
              <Row>
                <Col>
                  <p style={{ fontWeight: 600 }} className="text-start">
                    {t("recentOperation.viewDetailModal.payerAccount")}
                  </p>
                </Col>
                <Col>
                  <p className="text-end">{transactionData?.payer_account}</p>
                </Col>
              </Row>
            )} */}
          {/* {transactionData?.posting_key == "cr" &&
            transactionData?.payer_name && (
              <Row>
                <Col>
                  <p style={{ fontWeight: 600 }} className="text-start">
                    {t("recentOperation.viewDetailModal.payer")}
                  </p>
                </Col>
                <Col>
                  <p className="text-end">{transactionData?.payer_name}</p>
                </Col>
              </Row>
            )} */}

          <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p style={{ fontWeight: 600 }} className="text-start">
                {t("recentOperation.viewDetailModal.paymentRef")}
              </p>
            </Col>

            <Col xs={6} sm={6} md={6} lg={6}>
              <p className="text-end">
                {transactionData?.payment_reference}
                {/* {
                  // transactionData.posting_key === "dr" ?
                  //   (transactionData.details ? transactionData.details : transactionData.reference ? transactionData.reference : transactionData.external_ref)
                  //   : transactionData.reference ? transactionData.reference : transactionData.external_ref
                  transactionData?.details
                    ? transactionData?.details
                    : transactionData?.reference
                    ? transactionData?.reference
                    : transactionData?.external_ref
                } */}
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p style={{ fontWeight: 600 }} className="text-start">
                {t("recentOperation.viewDetailModal.currency")}:
              </p>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p className="text-end">{transactionData?.payment_currency}</p>
            </Col>
          </Row>

          <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p style={{ fontWeight: 600 }} className="text-start">
                {t("recentOperation.viewDetailModal.beneficiaryIban")}:
              </p>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p className="text-end">{transactionData?.beneficiary_iban}</p>
            </Col>
          </Row>

          {transactionData?.description && (
            <Row>
              <Col xs={6} sm={6} md={6} lg={6}>
                <p style={{ fontWeight: 600 }} className="text-start">
                  {t("recentOperation.viewDetailModal.description")}:
                </p>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6}>
                <p className="text-end">
                  {transactionData?.description
                    ? transactionData?.description
                    : "None"}
                </p>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p style={{ fontWeight: 600 }} className="text-start">
                {t("recentOperation.viewDetailModal.transactionType")}:
              </p>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p className="text-end" style={{ textTransform: "capitalize" }}>
                {transactionData?.isScheduling ? 'Scheduling': "Recurring"}
              </p>
            </Col>
          </Row>
          {/* {transactionData?.offsetting_transaction_type && (
            <Row>
              <Col xs={6} sm={6} md={6} lg={6}>
                <p style={{ fontWeight: 600 }} className="text-start">
                  {t("recentOperation.viewDetailModal.transactionType")}:
                </p>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6}>
                <p className="text-end" style={{ textTransform: "capitalize" }}>
                  {transactionData?.offsetting_transaction_type}
                </p>
              </Col>
            </Row>
          )} */}
          {/* {transactionData?.payment_scheme && (
            <Row>
              <Col xs={6} sm={6} md={6} lg={6}>
                <p style={{ fontWeight: 600 }} className="text-start">
                  {t("recentOperation.viewDetailModal.paymentScheme")}:
                </p>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6}>
                <p className="text-end" style={{ textTransform: "capitalize" }}>
                  {transactionData?.payment_scheme}
                </p>
              </Col>
            </Row>
          )} */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransactionDetail;
